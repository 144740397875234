/* eslint-disable no-useless-escape */
import * as types from "./../../store/types";
import { mapActions, mapGetters } from "vuex";
import $formData from "./../../mixins/fromDataGetter";
import $formatNumber from "./../../mixins/formatNumber";
import QrcodeVue from "qrcode.vue";
import paymentService from "./../../services/paymentService";
import Num2persian from "num2persian";
import { ASSETS_URL } from "../../services/base";
import currenciesService from "../../services/currenciesService";
import merchantService from "../../services/merchantService";
import merchantEmailComp from "@/components/merchant/merchantEmail";

import axios from "../../services/config/main-axios";
import authAxios from "../../services/config/need-auth-axios";
import bitrahAxios from "../../services/config/bitrah-axios";

const inputComp = () => import("./../../components/input/index.vue");
import { MAX_AMOUNT } from "@/utlities/currenciesminMaxAmount";
import $minValidation from "@/mixins/MinValueValidation";

export default {
  name: "merchant",
  components: {
    inputComp,
    QrcodeVue,
    merchantEmailComp
  },
  mixins: [$formData, $formatNumber, $minValidation],
  props: [],
  data() {
    return {
      baseUrl: ASSETS_URL,
      show: false,
      qrCodeToggle: false,
      showCard: false,
      isMerchantValid: false,
      merchantInfo: "",
      validate: {
        isvalid: true,
        message: ""
      },
      window: {
        width: 0,
        height: 0
      },
      timerInterval: null,
      requestInterval: "",
      size: 194,
      sizeSmall: 194,
      loading: false,
      calculatedValue: 0,
      convertedIcon: "",
      convertedTitle: this.$route.query.coin,
      convertedISO: this.$route.query.coin,
      convertedName: this.$route.query.coin,
      ValueToCalculate: 0,
      ValueToCalculateTextbox: 0,
      ValueToSuggestedFee: 0,
      mainRate: 0,
      countdownTimer: "",
      clockInterval: "",
      isExpired: false,
      isProcessed: false,
      paymentResponse: [],
      postFormBody: [],
      showCoinAmount: "",
      backUrl: "",
      status: "",
      statusMessage: "",
      paymentBtnDisable: false,
      showEmailCard: false
    };
  },
  computed: {
    ...mapGetters({
      currencies: types.GET_CURRENCIES,
      orderDetails: types.GET_ORDER_DETAILS,
      bitrahCurrencies: types.GET_BITRAH_CURRENCIES,
      getCalculateValue: types.GET_CALCULATE_VALUE
    }),
    toPersian() {
      if (this.ValueToCalculate) {
        let value = this.ValueToCalculate;
        parseFloat(value);
        if (typeof value === "string") {
          value = value.replace(/\,/g, "");
        }
        return Num2persian((value / 10).toFixed(0));
      } else {
        return 0;
      }
    }
  },
  beforeCreate() {
    const defaultLang = "en";
    axios.defaults.headers.common["Accept-Language"] = defaultLang;
    authAxios.defaults.headers.common["Accept-Language"] = defaultLang;
    bitrahAxios.defaults.headers.common["Accept-Language"] = defaultLang;
  },
  mounted() {
    if (this.$route.params.id !== "guest") {
      this.getBitrahCurrenciesFunc();
    }
    if (this.$route.query.amount) {
      this.calculateRialToCoin(
        this.$route.query.amount,
        this.$route.query.coin
      );
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    let merchantID = this.$route.params.id;
    if (this.$route.params.id !== "guest") {
      merchantService
        .getMerchantInfo(merchantID)
        .then(response => {
          this.isMerchantValid = true;
          this.show = true;
          this.merchantInfoAction(response.data);
          if (this.$route.query.mode === "off") {
            this.submit();
          } else {
            this.showCard = true;
          }
        })
        .catch(error => {
          this.isMerchantValid = false;
          this.show = true;
          this.$bvToast.toast(error.response.data.message, {
            title: "Error",
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          });
        })
        .finally(() => (this.loading = false));
      this.getCurrenciesFunc();
      this.getBitrahCurrenciesFunc();
    } else {
      this.isMerchantValid = true;
      this.show = true;
      this.showCard = true;
      this.getCurrenciesFunc();
      this.getBitrahCurrenciesFunc();
    }
  },
  methods: {
    ...mapActions({
      merchantInfoAction: types.ACTION_MERCHANT,
      currenciesAction: types.ACTION_CURRENCIES,
      orderDetailsAction: types.ACTION_ORDER_DETAILS,
      bitrahCurrenciesAction: types.ACTION_BITRAH_CURRENCIES,
      calculateValueAction: types.ACTION_CALCULATE_VALUE
    }),
    getBitrahCurrenciesFunc() {
      currenciesService
        .getBitrahCurrencies(this.$route.params.id)
        .then(response => {
          this.bitrahCurrenciesAction(response.data);
          for (let index = 0; index < response.data.length; index++) {
            const element = response.data[index];
            if (element.iso === this.$route.query.coin) {
              this.convertedIcon = element.logo;
            }
          }
        })
        .catch(error => {
          this.$bvToast.toast(error.response.data.message, {
            title: "Error",
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          });
        });
    },
    // qrCodeToggleFunc() {
    //   this.qrCodeToggle = !this.qrCodeToggle;
    // },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    onCopyToClipboard() {
      this.$bvToast.toast("Successfully copied", {
        title: "Successfully done",
        noCloseButton: true,
        variant: "custom",
        bodyClass: "successFontColor rtl text-right font-weight-bold py-3",
        headerClass: "successBackgroundColor successFontColor  rtl text-right",
        solid: false
      });
    },
    onErrorToClipboard() {
      this.$bvToast.toast("Failed to copy", {
        title: "Error",
        noCloseButton: true,
        variant: "custom",
        bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
        headerClass: "dangerBackgroundColor dangerFontColor  rtl text-right",
        solid: false
      });
    },
    changeCurrency(currency) {
      this.convertedIcon = currency.logo;
      this.convertedTitle = currency.name;
      this.convertedISO = currency.iso;
      this.calculateRialToCoin(this.ValueToCalculate, currency.iso);
    },
    calculateRialToCoin(value, iso) {
      if (this.$route.params.id !== "guest") {
        currenciesService
          .calculateValue(value, iso)
          .then(response => {
            this.calculateValueAction(response.data);
            this.calculatedValue = response.data.value;
            this.ValueToSuggestedFee = response.data.suggestedFee;
          })
          .catch(response => {
            this.$bvToast.toast(response.data.message, {
              title: "Error",
              noCloseButton: true,
              variant: "custom",
              bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
              headerClass:
                "dangerBackgroundColor dangerFontColor rtl text-right",
              solid: false
            });
          });
      } else {
        currenciesService
          .guestCalculateValue(value, iso)
          .then(response => {
            this.calculateValueAction(response.data);
            this.calculatedValue = response.data.value;
            this.ValueToSuggestedFee = response.data.suggestedFee;
          })
          .catch(response => {
            this.$bvToast.toast(response.data.message, {
              title: "Error",
              noCloseButton: true,
              variant: "custom",
              bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
              headerClass:
                "dangerBackgroundColor dangerFontColor rtl text-right",
              solid: false
            });
          });
      }
    },
    getCurrenciesFunc() {
      currenciesService
        .getCurrencies()
        .then(response => {
          this.currenciesAction(response.data).then(() => {
            let token = this.$route.query.token;
            if (token) {
              if (this.$route.params.id !== "guest") {
                this.getOrderDetails();
              } else {
                paymentService.guestOrderDetails(token).then(response => {
                  this.countdown(response.data.timeout);
                  this.ValueToCalculate = response.data.rialValue;
                  this.ValueToCalculateTextbox = this.$formatNumber(
                    response.data.rialValue
                  );
                  this.merchantInfo = response.data.businessName;
                  this.status = response.data.status;

                  this.backUrl = response.data.address;
                  this.backUrl =
                    this.backUrl &&
                    this.backUrl.replace("en", this.$i18n.locale);
                  this.calculatedValue = response.data.calculatedValue;

                  if (this.status === "TIMED_OUT") {
                    // this.statusMessage = this.$i18n.t(
                    //   "merchant.expire.requestExpired"
                    // );
                    this.statusMessage = "Your request expired";
                    this.$refs["modal-2"].show();
                    this.paymentBtnDisable = true;
                  }
                  if (this.status === "PAID") {
                    // this.statusMessage = this.$i18n.t(
                    //   "merchant.processed.Paid"
                    // );
                    this.statusMessage =
                      "This transaction has already been processed.";
                    this.$refs["modal-2"].show();
                    this.paymentBtnDisable = true;
                  }
                });
              }
            } else {
              if (this.$route.params.id !== "guest") {
                let merchantID =
                  this.$route.query.merchantId ||
                  localStorage.getItem("bitrahAccessToken");
                this.ValueToCalculate = this.$route.query.amount || "";
                paymentService.getMerchantDetails(merchantID).then(response => {
                  this.merchantInfo = response.data;
                });
              } else {
                this.merchantInfo = "Bitrah payment demo";
                this.ValueToCalculate = this.$route.query.amount || "";
              }
            }
          });
        })
        .catch(error => {
          this.$bvToast.toast(error.response.data.message, {
            title: "Error",
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          });
        });
    },
    getOrderDetails() {
      paymentService.getOrderDetails(this.$route.query.token).then(response => {
        this.showEmailCard = !response.data.email;
        this.countdown(response.data.timeout);
        this.ValueToCalculate = response.data.rialValue;
        this.ValueToCalculateTextbox = this.$formatNumber(
          response.data.rialValue
        );
        this.merchantInfo = response.data.businessName;
        this.status = response.data.status;

        this.backUrl = response.data.address;
        this.calculatedValue = response.data.calculatedValue;

        if (this.status === "TIMED_OUT") {
          // this.statusMessage = this.$i18n.t(
          //   "merchant.expire.requestExpired"
          // );
          this.statusMessage = "Your request expired";
          this.$refs["modal-2"].show();
          this.paymentBtnDisable = true;
        }
        if (this.status === "PAID") {
          // this.statusMessage = this.$i18n.t(
          //   "merchant.processed.Paid"
          // );
          this.statusMessage = "This transaction has already been processed.";
          this.$refs["modal-2"].show();
          this.paymentBtnDisable = true;
        }
      });
    },
    guestAddress() {
      this.isExpired = false;
      paymentService
        .getGuestAddress()
        .then(response => {
          if (
            !this.validateMinValue(this.ValueToCalculate, this.convertedISO) &&
            this.ValueToCalculate < MAX_AMOUNT
          ) {
            this.$bvModal.show("modal-1");
            let data = {};
            data.address = response.data;
            this.orderDetailsAction(data);
          }
        })
        .catch(error => {
          this.$bvToast.toast(error.response.data.message, {
            title: "Error",
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          });
        })
        .finally(() => (this.loading = true));
    },
    hideModal() {
      this.$bvModal.hide("modal-1");
      clearInterval(this.requestInterval);
    },
    cancelOrder() {
      this.$router.push(this.backUrl);
    },
    countdown(distance) {
      this.clockInterval = setInterval(() => {
        distance--;
        let minutes = Math.floor(distance / 60);
        let seconds = Math.floor(distance % 60);
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        if (seconds < 10) {
          seconds = "0" + seconds;
        }
        let x = minutes + ":" + seconds;
        if (distance < 0) {
          this.countdownTimer = "00:00";
          this.$bvModal.hide("modal-1");
          this.isExpired = true;
          this.paymentBtnDisable = true;
          this.loading = false;

          if (this.$route.params.id !== "guest") this.$bvModal.show("modal-2");

          clearInterval(this.clockInterval);
        } else {
          this.countdownTimer = x;
        }
      }, 1000);
    },
    submit() {
      this.loading = false;
      this.isExpired = false;
      this.isProcessed = false;
      let token = this.$route.query.token;

      paymentService
        .getAddress(token, this.convertedISO) //this.calculatedValue
        .then(response => {
          this.$bvModal.show("modal-1");
          this.orderDetailsAction(response.data);
          this.countdown(this.payment.expires_in);
        })
        .catch(error => {
          this.$bvToast.toast(error.response.data.message, {
            title: "Error",
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          });
        })
        .finally(() => (this.loading = true));
    },
    checkForm(e) {
      if (this.ValueToCalculate === 0) {
        this.$bvToast.toast("Enter an amount", {
          title: "Error",
          noCloseButton: true,
          variant: "custom",
          bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
          headerClass: "dangerBackgroundColor dangerFontColor  rtl text-right",
          solid: false
        });
      } else if (
        this.validateMinValue(this.ValueToCalculate, this.convertedISO)
      ) {
        // validateMinValue is defined as a mixin
        let minAmountMsg = this.validateMinValue(
          this.ValueToCalculate,
          this.convertedISO
        );
        this.$bvToast.toast(
          this.$i18n.t(`panel.merchant.pay.${minAmountMsg}`),
          {
            title: "Error",
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          }
        );
      } else if (this.ValueToCalculate > MAX_AMOUNT) {
        this.$bvToast.toast(
          "Please enter an amount lower than 5,000,000,000 Rials",
          {
            title: "Error",
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          }
        );
      } else {
        this.loading = false;
        this.isExpired = false;
        this.isProcessed = false;
        this.submit();
      }
      e.preventDefault();
    },
    verifyOrder() {
      if (this.$route.query.token) {
        paymentService.verifyOrder(this.$route.query.token).then(response => {
          if (response.data.callbackUrl === null) {
            if (response.data.status === 0) {
              this.isProcessed = true;
              this.loading = false;
            }
          } else {
            let form = document.createElement("form");
            let token = document.createElement("input");
            let coin = document.createElement("input");
            let value = document.createElement("input");
            let status = document.createElement("input");
            let orderId = document.createElement("input");
            let refId = document.createElement("input");
            form.method = "POST";
            form.style = "display:none";
            // form.style = "display:none";
            form.action = response.data.callbackUrl;
            token.value = this.$route.query.token;
            coin.value = response.data.coin;
            value.value = response.data.value;
            status.value = response.data.status;
            orderId.value = response.data.orderId;
            refId.value = response.data.refId;
            token.name = "token";
            coin.name = "coin";
            value.name = "value";
            status.name = "status";
            orderId.name = "orderId";
            refId.name = "refId";
            form.appendChild(token);
            form.appendChild(coin);
            form.appendChild(value);
            form.appendChild(status);
            form.appendChild(orderId);
            form.appendChild(refId);
            document.body.appendChild(form);
            form.submit();
          }
        });
      } else {
        if (this.$route.query.cashier) {
          paymentService
            .verifyCashierOrder(
              this.ValueToCalculate.replace(/\,/g, ""),
              this.calculatedValue,
              this.$route.query.coin,
              this.$route.query.merchantId,
              this.orderDetails.index
            )
            .then(response => {
              if (response.status === 200) {
                this.isProcessed = true;
                this.loading = false;
              }
            });
        } else {
          paymentService
            .verifyATMOrder(
              this.ValueToCalculate.replace(/\,/g, ""),
              this.calculatedValue,
              this.$route.query.coin,
              this.$route.query.merchantId,
              this.orderDetails.index
            )
            .then(response => {
              if (response.status === 200) {
                this.isProcessed = true;
                this.loading = false;
              }
            });
        }
      }
    },
    guestVerifyOrder() {
      this.$bvModal.hide("modal-1");
      this.$bvModal.show("modal-guest");
    },
    guestBackToBilling() {
      this.$router.push(`/${this.$i18n.locale}/payWithBitrah`);
    },
    guestCloseWindow() {
      this.$router.push(`/${this.$i18n.locale}`);
    },
    closeWindow() {
      if (this.$route.query.mode === "off" && this.$route.query.cashier) {
        window.close();
      } else {
        this.$router.push({
          path: `${this.$route.params.id}/customer`,
          query: { merchantId: this.$route.query.merchantId }
        });
      }
    }
  }
};
