import $formData from "../../../mixins/fromDataGetter";
import paymentService from "../../../services/paymentService";

const inputComp = () => import("../../input/index.vue");

export default {
  name: "merchant-email",
  components: {
    inputComp
  },
  mixins: [$formData],
  data() {
    return {
      show: true,
      validate: {
        isvalid: true,
        message: "Email is incorrect"
      },
      loading: false
    };
  },
  computed: {},
  mounted() {},
  methods: {
    submit() {
      this.$bvToast.hide();
      this.loading = true;
      if (this.form.email === undefined || this.form.email === "") {
        this.$bvToast.toast(
          this.$i18n.t("merchant.emailComponent.checkEmpty"),
          {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          }
        );
        this.loading = false;
      } else if (
        !this.form.email
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      ) {
        this.$bvToast.toast(
          this.$i18n.t("merchant.emailComponent.emailNotValid"),
          {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          }
        );
        this.loading = false;
      } else {
        const data = {
          email: this.form.email,
          token: this.$route.query.token
        };

        paymentService
          .submitCustomerEmail(data)
          .then(() => {
            this.$emit("updateOrderDetails");
          })
          .catch(error => {
            this.$bvToast.toast(error.response.data.message, {
              title: this.$i18n.t("toast.errorTitle"),
              noCloseButton: true,
              variant: "custom",
              bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
              headerClass:
                "dangerBackgroundColor dangerFontColor  rtl text-right",
              solid: false
            });
          })
          .finally(() => (this.loading = false));
      }
    }
  }
};
