import { render, staticRenderFns } from "./merchantEmail.html?vue&type=template&id=2453ec5e&scoped=true&"
import script from "./merchantEmail.js?vue&type=script&lang=js&"
export * from "./merchantEmail.js?vue&type=script&lang=js&"
import style0 from "./merchantEmail.scss?vue&type=style&index=0&id=2453ec5e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2453ec5e",
  null
  
)

export default component.exports